import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from 'exenv';
import React, {
  FC,
  useEffect,
  ReactNode,
  ReactElement,
} from 'react';
import { useLocation } from 'react-router';
import Modal from '_components/Modal';
import DigitalDataProvider from '_containers/DigitalDataContext';
import { LocationAPIContextProvider } from '_containers/LocationAPIContext';
import MemberProvider from '_containers/MemberContext';
import MenuProvider from '_containers/MenuContext';
import ModalProvider from '_containers/ModalContext';
import TimezoneProvider from '_containers/TimezoneContext';
import CountryProvider from '_containers/CountryContext';
import { getQueryStringValue } from '_utils/data';
import { getPropertyByKey } from '_utils/helpers/generics';
import {
  SpaLoader,
  Grid,
} from '_utils/components';
// Structured data for google
import { 
  CorpAllPagesStructuredData,
  SitelinkBoxHomePageStructuredData
} from '_utils/components/StructuredDataCorp'
import { INVISIBLE_CLASS } from '_utils/helpers/render';
import { MainContainer } from '_layouts/SharedStyledLayout';

import { Language } from '_types';

interface LayoutProps {
  context: any;
  route: any;
  loading?: boolean;
}

/**
 * DEFAULT APP LAYOUT
 */
const DefaultLayout: FC<LayoutProps> = ({
  context,
  route,
  loading
}): JSX.Element => {
  const language = getPropertyByKey<Language>('value', getPropertyByKey('languageType', route?.fields)) || null;

  const { search } = useLocation();

  useEffect(() => {
    const fileToDownload = getQueryStringValue('file', search);

    if (!loading && fileToDownload) {
      location.href = fileToDownload;
    }
  }, [search, loading]);

  return (
    <>
      <MenuProvider>
        <ModalProvider>
          <MemberProvider>
            <DigitalDataProvider>
              <TimezoneProvider>
                <CountryProvider>
                <LocationAPIContextProvider>
                  <Placeholder name="jss-header" rendering={route} />
                  <MainContainer
                    id="main-content"
                    lang={language}
                    name="jss-main"
                    rendering={route}
                    role="main"
                  >
                    <SpaLoader />

                    {/* prevents flash reverted content when route change and personalisation applied */}
                    <div className={loading ? INVISIBLE_CLASS : ''}>
                      <Placeholder name="jss-content-intro" rendering={route} />
                      <Placeholder name="jss-content-main" rendering={route} />

                      <Placeholder
                        name="jss-content-footer"
                        rendering={route}
                        render={(components: ReactNode[]) =>
                          <>
                            {components
                              .filter((component: ReactNode) => (component !== null && component !== undefined))
                              .map((component: ReactElement, index: number) => {

                                if (component.props && component.props?.type !== 'text/sitecore') {
                                  return <Grid.Container key={index}>
                                    <Grid.Row>
                                      <Grid.Item>{components}</Grid.Item>
                                    </Grid.Row>
                                  </Grid.Container>
                                }
                                return component;
                              })}
                          </>
                        }
                      />

                      <Placeholder name="jss-content-outro" rendering={route} />
                    </div>

                  </MainContainer>
                  <Placeholder name="jss-footer" rendering={route} />
                  <Modal />
                  {/* Google Structured data */}
                  <SitelinkBoxHomePageStructuredData context={context}/>
                  <CorpAllPagesStructuredData context={context} />
                </LocationAPIContextProvider>
                </CountryProvider>
              </TimezoneProvider>
            </DigitalDataProvider>
          </MemberProvider>
        </ModalProvider>
      </MenuProvider>
      <VisitorIdentification />
    </>
  );
};

export default DefaultLayout;
