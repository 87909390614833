import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import buttonSettings from '_containers/Theme/corporate/button'

import { Grid } from '_utils/components';
import { spacing } from '_utils/props';
import {
  button,
  listReset,
  mq,
  svg,
  textBody2,
} from '_utils/styles';

export const Footer = styled.footer`
  ${({ theme, shouldHideBackToTop }) => spacing({ theme,
    pt: {
      xs: shouldHideBackToTop ? 0 : 3,
      md: 0
    }
  })};
`;

export const Container1 = styled.div`
  ${({ theme }) => spacing({ theme, pt: '48px', pb: { xs: 7, md: 6 } })};
  background-color: ${({ theme }) => theme.pageFooter.background};
  color: ${({ theme }) => theme.pageFooter.color};
  width: 100%;

  @media print {
    display: none;
  }
`;

export const Container2 = styled.div`
  ${({ theme }) => spacing({ theme, pb: '32px' })};
  background-color: ${({ theme }) => theme.pageFooter.background};
  color: ${({ theme }) => theme.pageFooter.color};
  width: 100%;

  @media print {
    display: none;
  }
`;

export const Column = styled(Grid.Item)`
  ${({ theme }) => spacing({ theme, mb: '24px', py: 0 })};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LogoWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mb: { xs: 7, md: 5 } })};
  ${svg(120, 52)};
  height: 52px;
  width: 120px;
`;

export const List = styled.ul`
  ${listReset}
`;

export const ListItem = styled.li`
  &:last-child > a {
    margin-bottom: 0;
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mq('lg')} {
    flex-direction: row;
  }
  
`

export const SocialIconsGroupOne = styled.div`
  display: flex;
  margin-right: 18px;
`

export const SocialIconsGroupTwo = styled.div`
  display: flex;
  margin-top: 18px;
  ${mq('lg')} {
    margin-top: 0;

  }
`

export const SocialListItem = styled.div`
  width: 24px;
 
  & + & {
    margin-left: 18px;
  }

`;

const linkStyle = css`
  color: inherit;
  display: inline-block;
  line-height: 1;
`;

export const PrimaryLink = styled(Link)`
  ${linkStyle}
  font-family: ${({ theme }) => theme?.pageFooter?.primaryLink?.fontFamily};
  font-weight: ${({ theme }) => theme?.pageFooter?.primaryLink?.fontWeight};
  ${({ theme }) => theme?.pageFooter?.primaryLink?.level}
  ${({ theme }) => spacing({ theme, mb: '16px' })};
  text-decoration: none;
`;

export const SecondaryLink = styled(Link)`
  ${linkStyle}
  ${({ theme }) => spacing({ theme, mb: '18px' })};
`;

export const BottomList = styled.ul`
  ${listReset}

  ${mq('sm')} {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const BottomLink = styled(Link)`
  ${linkStyle};
  ${textBody2};
  ${({ theme }) => spacing({ theme, mb: '16px' })};

  ${mq('sm')} {
    margin-bottom: 0;
    margin-right: 28px;
  }
`;

export const PrivacyPreferencesLink = styled.a`
  ${linkStyle};
  ${textBody2};
  ${({ theme }) => spacing({ theme, mb: '16px' })};

  ${mq('sm')} {
    margin-bottom: 0;
    margin-right: 28px;
  }
`;

export const SocialLink = styled(Link)`
  ${linkStyle};
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`;


export const StyledButton = styled(button)`
  &:last-child > a {
    margin-bottom: 0;
  }
  text-decoration: underline;
  background: transparent !important;
  ${linkStyle};
  ${textBody2};
`

// 'get the latest', 'stay in touch' elems
export const SocialTitle = styled.div`
  color: ${({ theme }) => theme.pageFooter.labelColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const SubscribeLink = styled(Link)`
  ${button('slim', 'tertiaryDark')};
  border-radius: ${Object.values(buttonSettings?.slim?.borderRadius).map(corner => `${corner}px `)};

  ${({ theme }) => spacing({ theme, mb: 5 })};
`;

export const ImagesContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    padding: 0.75rem;
    width: 80px;
  }
`