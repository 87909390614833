import { compose } from 'ramda';
import React, { FC } from 'react';

import Ad from '_components/Advertisement/Ad';
import { ImageBanner } from '_components/PageBanner/ImageBanner';
import { TextBanner } from '_components/PageBanner/TextBanner';
import {
  withDataSourceValidation,
  withEditMode,
  withSitecoreContext,
} from '_containers/BaseComponent';
import { normalizeJssFields } from '_utils/helpers/jss';
import { isCorporateSite as isCorporateSiteContext } from '_utils/helpers/render';
import { PageBannerFieldValidators } from './validation';

import {
  ImageBannerProps,
  NormalisedPageBannerPageFields,
  PageBannerProps,
  RenderingParams,
  TextBannerProps,
} from './definitions';

import { AdImageBanner, AdTextBanner } from './StyledPageBanner';

const PageBanner: FC<PageBannerProps> = ({
  editMode,
  rendering,
  sitecoreContext,
}): JSX.Element => {
  const isCorporateSite = isCorporateSiteContext(sitecoreContext);
  const renderingPageFields: NormalisedPageBannerPageFields = normalizeJssFields(rendering?.fields?.data?.pageFields);
  const renderingParams: RenderingParams = normalizeJssFields(rendering);

  const isImageBanner = (renderingParams?.params?.showImage ?? false) && (renderingPageFields?.Image?.value?.src ?? false);

  // If is ITB & Image: Black. Else: pageBannerTheme colour or if pageBannerTheme is undefined: Blue.
  const pageBannerTheme = (!isCorporateSite && isImageBanner)
    ? 'Black'
    : renderingParams?.params?.pageBannerTheme ?? 'Blue';

  const uid = rendering?.uid;

  // LinkItem(NavLink) expects obj.
  const categoryInformationLinkObj = {
    value: {
      id: '',
      href: renderingPageFields?.categoryInformation?.categoryUrl,
      text: renderingPageFields?.categoryInformation?.categoryLabelText
    }
  };

  const linkField = isCorporateSite
    ? renderingPageFields?.CTAbutton
    : categoryInformationLinkObj;

  const pageBannerCallToActionSecondaryLink = isCorporateSite
  ? renderingPageFields?.CTASecondaryLink
  : categoryInformationLinkObj;

  const adverts = rendering?.fields?.data?.datasource?.adverts;
  const pageFields = rendering?.fields?.data?.pageFields;
  const topicTagArray = pageFields?.topictags?.topics || [];
  const category = pageFields?.categoryInformation?.categoryLabelText;
  const topics = topicTagArray.map(item => item.topic);
  const keywords = [...topics, category].join();

  const adProps = {
    adSlotID: adverts?.adSlotID,
    editMode,
    keywords,
    position: adverts?.position,
    template: pageFields?.template?.name,
    uid: uid.concat('-ads'),
  }

  const imageBannerProps: ImageBannerProps = {
    pageTitle: renderingPageFields?.Heading,
    pageDescription: renderingPageFields?.Description,
    pageImage: renderingPageFields?.Image,
    linkField,
    pageBannerTheme,
    editMode,
    uid,
    trackingName: renderingPageFields?.trackingName,
    datasourceName: renderingPageFields?.datasourceName,
    pageBannerCallToActionSecondaryLink,
    pageBannerCallToActionSecondaryCSSClass: renderingPageFields?.CTASecondaryCssClass?.value,
    pageBannerCallToActionSecondaryIcon: renderingPageFields?.CTASecondaryIcon?.displayName,
    isCorporateSite
  };

  const textBannerProps: TextBannerProps = {
    pageTitle: renderingPageFields?.Heading,
    pageDescription: renderingPageFields?.Description,
    linkField,
    editMode,
    uid,
    isCorporateSite,
    pageBannerCallToActionSecondaryLink,
    pageBannerCallToActionSecondaryCSSClass: renderingPageFields?.CTASecondaryCssClass?.value,
    pageBannerCallToActionSecondaryIcon: renderingPageFields?.CTASecondaryIcon?.displayName,
  };

  return (
    <>
      {isImageBanner ?
        <>
          <ImageBanner {...imageBannerProps} />
          {!isCorporateSite && adverts &&
            <AdImageBanner>
              <Ad {...adProps} />
            </AdImageBanner>
          }
        </>
        :
        <>
          {!isCorporateSite && adverts &&
            <AdTextBanner className="adTextBanner">
              <Ad {...adProps} />
            </AdTextBanner>
          }
          <TextBanner {...textBannerProps} />
        </>
      }
    </>
  );
};

export default compose(
  withSitecoreContext(),
  withEditMode,
  withDataSourceValidation(PageBannerFieldValidators, false),
)(PageBanner);
