export enum LinkTypes {
  External = 'external',
  Internal = 'internal'
}

export enum LinkTheme {
  Breadcrumb = 'breadcrumb',
  Featured = 'featured',
  Primary = 'primary',
  Secondary = 'secondary',
  None = 'none'
}

export enum LinkSize {
  Default = 'default',
  Small = 's'
}

export type LinkProps = React.PropsWithChildren<{
  analyticsOptions?: {
    analyticsTrackingName?: string;
    datasourceName?: string;
  };
  editMode?: boolean;
  className?: string;
  fallbackTitle?: string;
  field?: {
    value?: {
      anchor?: string;
      class?: string;
      href?: string;
      id?: string;
      linktype?: LinkTypes;
      querystring?: string;
      text?: string;
      title?: string;
      target?: string;
    };
  };
  linkTheme?: LinkTheme;
  linkSize?: LinkSize;
  linkAriaCurrent?: string | null;
  tag?: string;
  childrenOnly?: boolean;
  childrenAfterText?: boolean;
  disabled?: boolean;
  as?: string;
  hasNoHref?: boolean;
  hideExternalIcon?: boolean;
  iconName?: any;
}>;
