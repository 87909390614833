import { Image as SCImage } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

import { ITheme } from '_containers/Theme/corporate/definitions';
import { Grid, Link } from '_utils/components';
import {
  BookHingeParent,
  BookHingeSVGContainer,
} from '_utils/components/BookHinge/StyledBookHinge';
import { spacing } from '_utils/props';
import {
  avoidPageBreakOnPrint,
  button,
  headingH1,
  mq,
  subheading1,
  textBody1,
} from '_utils/styles';
import { PageBannerTheme, InTheBlackPageBannerTheme } from './definitions';

const getPageBannerThemeColor = ({
  theme,
  pageBannerTheme,
}: {
  theme: ITheme;
  pageBannerTheme?: PageBannerTheme | InTheBlackPageBannerTheme;
}): string => {
  if (pageBannerTheme?.toLocaleLowerCase() === 'black') {
    return pageBannerTheme;
  }
  return pageBannerTheme &&
    theme.pageBanner.background[pageBannerTheme?.toLowerCase()];
}

const lightThemeBanner = (pageBannerTheme?: PageBannerTheme): string =>
  ['orange', 'teal', 'yellow'].includes(pageBannerTheme?.toLowerCase())
    && 'lightBanner';

const darkThemeBanner = (pageBannerTheme?: PageBannerTheme): string =>
  ['blue', 'purple', 'black'].includes(pageBannerTheme?.toLowerCase())
    && 'darkBanner';

const getPageBannerThemeType = (pageBannerTheme?: PageBannerTheme): string =>
  ['blue', 'purple', 'black'].includes(pageBannerTheme?.toLowerCase())
    ? 'darkBanner'
    : 'lightBanner';

export const Container = styled.div`
  background-color: ${getPageBannerThemeColor};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq('lg')} {
    align-items: center;
    display: flex;
    min-height: 440px;
  }

  @media print {
    background-color: ${({ theme }) => theme.colors.background.tint1};
    color: ${({ theme }) => theme.colors.element.neutralBlack};
  }
`;

export const TextBannerContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 7, mb: 6 })}
  background-color: ${getPageBannerThemeColor};
  overflow: hidden;
  position: relative;
  width: 100%;

  .adTextBanner + &.page-banner {
    margin-top: 0 !important;
  }

  ${mq('lg')} {
    align-items: center;
    display: flex;
  }

  @media print {
    background-color: ${({ theme }) => theme.colors.background.tint1};
    color: ${({ theme }) => theme.colors.element.neutralBlack};
  }
`;

export const CategoryLabelLink = styled(Link)`
  ${textBody1};
  a {
    color: ${({ theme }) => theme.colors.ui.accent1};
    font-size: 14px;
    font-weight: 800;
    light-height: 24px;
    text-transform: uppercase;
  }
`;

export const Header = styled.h1`
  ${headingH1}
  color: ${({ theme, pageBannerTheme }) =>
    theme.pageBanner[getPageBannerThemeType(pageBannerTheme)].headerColor};
  margin-bottom: 0;

  /* mq specificity required to override default heading margin-bottom */
  ${mq('md')} {
    margin-bottom: 0;
  }
`;

export const Blurb = styled.p`
  ${subheading1}
  ${({ theme }) => spacing({ theme, mt: '16px' })}
  color: ${({ theme, pageBannerTheme }) =>
    theme.pageBanner[getPageBannerThemeType(pageBannerTheme)].color};
  margin-bottom: 0;

  /* mq specificity required to override default heading margin-bottom */
  ${mq('md')} {
    margin-bottom: 0;
  }
`;

export const TagListContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const BannerImage = styled(BookHingeParent)`
  background-color: ${getPageBannerThemeColor};
  display: block;
  height: 212px;
  position: relative;
  width: 100%;

  ${mq('md')} {
    height: 100%;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }

  @media print {
    height: 200px;
    position: relative;
    width: 100%;
  }

  ${BookHingeSVGContainer} {
    path {
      fill: ${getPageBannerThemeColor};
    }
  }
`;

export const Figure = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
`;

export const Image = styled(SCImage)`
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  ${avoidPageBreakOnPrint}
`;

export const BannerContent = styled(Grid.Item)`
  ${({ theme }) =>
    spacing({
      theme,
      py: { xs: 5, md: 5, lg: 8 },
      pl: { xs: 0, lg: 4, xl: 0 },
      pr: { xs: 0, md: 3, lg: 4 },
    })};

  @media print {
    ${({ theme }) => spacing({ theme, py: 3, px: 1 })};
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const TextBannerContent = styled(Grid.Item)`

  @media print {
    ${({ theme }) => spacing({ theme, py: 4, px: 1 })};
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const LinkButton = styled(Link)`
  ${({ theme }) => spacing({ theme, pt: 3 })};
  a {
    ${button('default', 'secondary')};
    ${textBody1};
    margin-bottom: 0;
    min-width: 140px;

    path {
      fill: ${({ theme }) => theme.colors.element.neutralWhite};
    }
  }

  @media print {
    display: none !important;
  }
`;

export const LinksDiv = styled.div `
  @media screen and (min-width: 768px) {
    display: flex;
  }
  div {
    padding-left: 12px;
  }
  @media screen and (max-width: 767px) {
    div {
      display: block !important;
    }
  }
`

export const SecondaryLinkButton = styled(Link)`
  ${({ theme }) => spacing({ theme, pt: 3 })};

  a {
      ${button('default', 'tertiary')}
      ${textBody1};
      margin-bottom: 0;
      min-width: 140px;
      border: 0;

      &:hover, &:active {
        background-color: transparent !important;

        path {
          fill: currentColor;
        }
      }
    }
  }

  @media print {
    display: none !important;
  }
`;

export const SecondaryLinkButtonDark = styled(Link)`
  ${({ theme }) => spacing({ theme, pt: 3 })};
  a {
    ${button('default', 'tertiaryDark')};
    ${textBody1};
    margin-bottom: 0;
    min-width: 140px;
    vertical-align: text-top;
    padding: 9px 20px;
    border: 0;

    path {
      fill: ${({ theme }) => theme.colors.element.neutralWhite};
    }
  }

  @media print {
    display: none !important;
  }
`;

export const AdImageBanner = styled.div`
  ${({ theme }) => spacing({ theme, mt: '24px' })}
`;

export const AdTextBanner = styled.div`
  ${({ theme }) => spacing({ theme, my: '24px' })}
`;

  