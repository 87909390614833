import { Text } from '@sitecore-jss/sitecore-jss-react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  Grid,
  Link,
} from '_utils/components';
import {
  Container as CarouselContainer,
  PrevNextPosition,
} from '_utils/components/Carousel/StyledCarousel';
import {
  avoidPageBreakOnPrint,
  button,
  link,
  linkWithoutVisited,
  mq,
  showOnPrint,
  svg,
  textBody2
} from '_utils/styles';
import { spacing } from '_utils/props';

export const Header = styled.div`
  color: ${({ theme }) => theme.cardList.color};

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.cardList.headingColor ? theme.cardList.headingColor : null};
  }
`;

export const ListItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.is-hidden {
    display: none;
    ${showOnPrint}
  }

  @media print {
    display: inline-block !important;
    margin:0;
    width: 33%;
  }
`;

export const Container = styled(Grid.Container)`
  // remove padding if child of Single Column placeholder (ie. within Dashboard)
  .ph-sc & {
    padding: 0;
  }

  background: ${({ theme }) => theme.cardList.background};

  // if carousel, ensure shadows aren't cut off and apply negative margin to extend full width in < lg breakpoints
  ${CarouselContainer} {
    ${({ theme }) => spacing({
  theme,
  ml: {
    xs: `-${theme.grid.gutter.size.xs}px`,
    md: `-${theme.grid.gutter.size.md}px`,
    lg: `-${theme.grid.gutter.size.lg}px`,
  },
  mr: {
    xs: `${theme.grid.gutter.size.xs * 2}px`,
    md: `${theme.grid.gutter.size.md * 2}px`,
    lg: `${theme.grid.gutter.size.lg * 2}px`,
  }
})}

    width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.xs * 2}px)`};

    ${mq('md')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.md * 2}px)`};
    }

    ${mq('lg')} {
      width: ${({ theme }) => `calc(100% + ${theme.grid.gutter.size.lg * 2}px)`};
    }

    .flickity-slider {

      ${avoidPageBreakOnPrint}

      @media print {
        overflow: hidden;
        white-space: pre-wrap;
      }

      ${ListItem} {
        ${({ theme }) => spacing({
  theme,
  pl: {
    xs: `${theme.grid.gutter.size.xs}px`,
    md: 3,
    lg: 0,
  },
  pr: {
    xs: 0,
    md: 0,
    lg: `${theme.grid.gutter.size.lg + (theme.grid.gutter.size.lg / 3)}px`,
  },
  py: {
    xs: 5,
    md: 3,
    lg: 3,
  },
})}

        ${mq('lg')} {
          transform: ${({ theme }) => `translateX(${theme.grid.gutter.size.lg}px)`};
        }
      }

      /* In order to get the sizing correct and prevent cut off of box shadow on every 3rd card */
      ${mq('lg')} {
        > div:nth-of-type(3n) {
          margin-right: ${({ theme }) => `${(theme.grid.gutter.size.lg / 3) * 2}px`};
          width: ${({ theme }) => `calc(33.3% - ${(theme.grid.gutter.size.lg / 3) * 2}px)`};

          &.is-selected {
            margin-right: 0;
          }
        }

        .is-selected + div:not(.is-selected) {
          > a {
            box-shadow: none !important;
          }
        }

        > div:nth-last-child(3) {
          &.is-selected {
            ${({ numItems }) => {
    if (numItems % 3 === 0) {
      return css`
                            transform: ${({ theme }) => `translateX(${(theme.grid.gutter.size.lg / 3) * 2}px)`};
                          `
    }
  }}
          }
        }

        > div:nth-last-child(2) {
          &.is-selected {
            ${({ numItems }) => {
    if (numItems % 3 === 0 || numItems % 3 === 2) {
      return css`
                            transform: ${({ theme }) => `translateX(${(theme.grid.gutter.size.lg / 3) * 2}px)`};
                          `
    }
  }}
          }
        }
      }

      /* Corrections to the last card, to allow it to sit on the end edge with shadow overflowing right */
      > div:last-of-type {
        ${({ theme }) => spacing({
    theme,
    pr: {
      xs: `${theme.grid.gutter.size.xs}px`,
      md: `${theme.grid.gutter.size.md}px`,
      lg: `${theme.grid.gutter.size.lg + (theme.grid.gutter.size.lg / 3)}px`,
    },
  })}

        &.is-selected {
          ${mq('lg')} {
            transform: ${({ theme }) => `translateX(${(theme.grid.gutter.size.lg / 3)}px)`};
          }
        }
      }
    }

    ${PrevNextPosition} {
      &.prev {
        ${({ theme }) => css`
          left: ${theme.grid.gutter.size.xs}px;

          ${mq('md')} {
            left: ${theme.grid.gutter.size.md}px;
          }

          ${mq('lg')} {
            left: 64px;
          }
        `}
      }

      &.next {
        ${({ theme }) => css`
          right: ${theme.grid.gutter.size.xs}px;

          ${mq('md')} {
            right: ${theme.grid.gutter.size.md}px;
          }

          ${mq('lg')} {
            right: 64px;
          }
        `}
      }
    }
  }
`;

export const List = styled(Grid.Row)`
  list-style: none;
  padding: 0;

  /* beat specificity of grid breakpoints */
  && {
    margin-top: 0;
  }

  ${avoidPageBreakOnPrint}

  @media print {
    display: block;
    width: 100%;
  }
`;

export const ShowMoreContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const FooterLinkContainer = styled.div`
  ${({ theme }) => spacing({ theme, mt: 3 })}
`;

export const FooterLink = styled(NavLink)`
  ${link};
`;

export const FooterLinkLabel = styled(Text)`
  ${textBody2}
`;

export const ArrowWrapper = styled.span`
  ${svg(14, 14)}
  margin-left: 6px;
  margin-right: 2px;
  width: 14px;

  path {
    fill: ${({ theme }) => theme.cardList.action};
  }
`;

export const LinkButton = styled(Link)`
  a {
    ${linkWithoutVisited}
    ${({ theme }) => button('slim', theme.cardList.ctaColor)};
    width: 100%;

    ${mq('md')} {
      width: auto;
    }
  }
`;

export const LinkWrapper = styled.div`
  ${({ theme }) => spacing({ theme, mt: 4, mb: 7 })};

  &.hideOnMobile {
    display: none;
  }

  ${mq('md')} {
    &.hideOnMobile {
      display: block;
    }

    &.showOnMobile {
      display: none;
    }

   ${({ theme }) => spacing({ theme, my: 0 })};
  }

  @media print {
    display: none !important;
  }
`;

export const ViewAllArrowWrapper = styled.span`
  ${svg(18, 18)}
  margin-left: 13px;
  margin-right: 2px;
  width: 14px;
  float: right;
  top: 4px;
  
  path {
    fill: ${({ theme }) => theme.cardList.action};
  }
`;