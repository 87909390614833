import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React, {
  FC,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useOneTrustActiveGroups, useScrollDepthAnalyticsTracking } from '_utils/hooks';
import asyncComponent from '_containers/AsyncComponent';
import { withCoveoNoIndexTags } from '_containers/BaseComponent';
import BackToTop from '_components/BackToTop';
import Icon from '_components/Icon';
import NavItem from '_components/NavItem';
import { Grid } from '_utils/components';
import ScreenReader from '_utils/components/ScreenReader';
import { emitTrackEvent } from '_utils/helpers/analytics';
import { isValidNavLink } from '_utils/validationChecks'
import { PageFooterProps } from './definitions';

import {
  BottomLink,
  PrivacyPreferencesLink,
  BottomList,
  Column,
  Container1,
  Container2,
  Footer,
  List,
  ListItem,
  LogoWrapper,
  PrimaryLink,
  SecondaryLink,
  SocialIconsWrapper,
  SocialIconsGroupOne,
  SocialIconsGroupTwo,
  SocialLink,
  SocialListItem,
  SocialTitle,
  SubscribeLink,
  ImagesContainer
} from './StyledPageFooter';

const AsyncLogo: any = asyncComponent(() =>
  import(/* webpackChunkName: "Logo" */ '_utils/icons/Logo')
);

const socialLinkMapGroupOne = {
  facebook: {
    iconName: 'facebook',
    title: 'facebook',
  },
  linkedin: {
    iconName: 'linked_in',
    title: 'linkedin',
  },
  instagram: {
    iconName: 'instagram',
    title: 'instagram',
  },
  twitter: {
    iconName: 'twitter',
    title: 'twitter',
  }
};

const socialLinkMapGroupTwo = {
  tiktok: {
    iconName: 'tiktok',
    title: 'tiktok',
  },
  wechat: {
    iconName: 'wechat',
    title: 'wechat',
  },
  weibo: {
    iconName: 'weibo',
    title: 'weibo',
  },
  threads: {
    iconName: 'threads',
    title: 'threads',
  }
};

const socialLinks = (validItems, linkMap) => {
  const [t] = useTranslation();  
  return validItems.map((item, index) => {
    const linkData = item?.link || {};
    const iconName = linkMap[item?.title?.value?.toLowerCase()]?.iconName;
  
    return (
      <SocialListItem key={index}>
        <NavItem link={linkData} StyledLink={SocialLink}>
          <ScreenReader>{t(`page-footer-accessible-social-label-${item?.title?.value}`)}</ScreenReader>
          <Icon ariaHidden={true} name={iconName} />
        </NavItem>
      </SocialListItem>
    );
  });
}

const footerLinkItem = (item, index, LinkComponent) => {
  const linkTitle = item?.title?.value || item?.link?.text || item?.url?.text || '';
  return (isValidNavLink(item)) && (
    <ListItem key={index}>
      <NavItem
        children={linkTitle}
        link={item.link}
        lang={item?.lang?.value}
        StyledLink={LinkComponent}
      />
    </ListItem>
  );
};

const filterLinks = (socialLinkMap, linksArray) => {
  const socialKeys = Object.keys(socialLinkMap);
  return linksArray
    .filter(linkObj => socialKeys.includes(linkObj?.title?.value?.toLowerCase()) && isValidNavLink(linkObj))
};

const PageFooter: FC<PageFooterProps> = ({
  rendering,
  sitecoreContext,
}) => {

  // Dispatch 100% scroll depth analytics 
  const ref = useRef();
  const dispatchFullPageScrollEmitTrack = () => emitTrackEvent('scroll100');
  useScrollDepthAnalyticsTracking(dispatchFullPageScrollEmitTrack, 100, ref);

  const {isOneTrustScriptReady} = useOneTrustActiveGroups();

  const [t] = useTranslation();

  const datasource = rendering?.fields?.data?.datasource || null;

  const shouldHideBackToTop = sitecoreContext?.route?.fields?.hideBackToTop?.value ?? false;

  const primaryNavigation = datasource?.firstColumnLinks?.[0] || [];
  const secondaryNavigation = datasource?.secondColumnLinks?.[0] || [];
  const tertiaryNavigation = datasource.thirdColumnLinks?.[0] || [];
  const bottomNavigation = datasource.bottomLinks?.[0] || [];
  const acknowledgmentCountry = datasource?.acknowledgmentCountry?.[0]?.messages?.[0]?.message?.value || [];
  const aboriginalFlagSrcLink = datasource?.acknowledgmentCountry?.[0]?.messages?.[0]?.flagAboriginal?.src || [];
  const torresStraitFlagSrcLink = datasource?.acknowledgmentCountry?.[0]?.messages?.[0]?.flagTorresStraitIslander?.src || [];
  const primaryItems = primaryNavigation?.links || [];
  const secondaryItems = secondaryNavigation?.links || [];
  const tertiaryItems = tertiaryNavigation?.links || [];
  const bottomItems = bottomNavigation?.links || [];


  const socialMediaLinks = tertiaryNavigation?.socialMediaLinks || [];

  const filteredGroupOneLinks = filterLinks(socialLinkMapGroupOne, socialMediaLinks);
  const filteredGroupTwoLinks =  filterLinks(socialLinkMapGroupTwo, socialMediaLinks);
  const validSocialLinks = [...filteredGroupOneLinks, ...filteredGroupTwoLinks];

  // For the top two links in third comlumn
  const subscribeLink = tertiaryNavigation?.link;
  const communityLink = tertiaryNavigation?.communityLink;

  if (!datasource) {
    return null;
  }

  return (
    <Footer
      className="page-footer"
      id="page-footer"
      ref={ref}
      shouldHideBackToTop={shouldHideBackToTop}
    >
      {!shouldHideBackToTop && (
        <div className="hide-on-print">
          <BackToTop />
        </div>
      )}

      <Placeholder name="jss-footer-promo" rendering={rendering} />

      <Container1>
        <Grid.Container>
          <Grid.Row config={{ gutters: ['left', 'right'] }}>
            <Column config={{ col: { xs: 12, sm: 6, md: 4 } }}>
              <LogoWrapper>
                <AsyncLogo ariaHidden={true} fill={null} />
              </LogoWrapper>
              {!!(primaryItems.length) && (
                <nav aria-label="footer primary">
                  <List>
                    {primaryItems.map((item, index) => footerLinkItem(item, index, PrimaryLink))}
                  </List>
                </nav>
              )}
            </Column>

            {!!(secondaryItems.length) && (
              <Column config={{ col: { xs: 12, sm: 6, md: 4 } }}>
                <nav aria-label="footer secondary">
                  <List>
                    {secondaryItems.map((item, index) => footerLinkItem(item, index, SecondaryLink))}
                  </List>
                </nav>
              </Column>
            )}

            {!!(tertiaryItems.length || validSocialLinks.length || subscribeLink) && (
              <Column config={{ col: { xs: 12, sm: 6, md: 4 } }}>
                {!!(subscribeLink) && (
                  <>
                    <SocialTitle>{t('page-footer-subscribe-title')}</SocialTitle>
                    <NavItem
                      children={subscribeLink?.text || subscribeLink?.fallbackTitle || subscribeLink?.url}
                      link={subscribeLink}
                      StyledLink={SubscribeLink}
                    />
                  </>
                )}

                {!!(communityLink) && (
                  <>
                    <SocialTitle>{t('page-footer-community-connect-title')}</SocialTitle>
                    <NavItem
                      children={communityLink?.text || communityLink?.fallbackTitle || communityLink?.url}
                      link={communityLink}
                      StyledLink={SubscribeLink}
                    />
                  </>
                )}

                {!!(validSocialLinks.length) && (
                  <>
                    <SocialTitle>{t('page-footer-social-title')}</SocialTitle>
                    <SocialIconsWrapper>
                      <SocialIconsGroupOne>{socialLinks(filteredGroupOneLinks, socialLinkMapGroupOne)}</SocialIconsGroupOne>
                      <SocialIconsGroupTwo>{socialLinks(filteredGroupTwoLinks, socialLinkMapGroupTwo)}</SocialIconsGroupTwo>
                    </SocialIconsWrapper>
                    
                  </>
                )}

                {!!(tertiaryItems.length) && (
                  <nav aria-label="footer tertiary">
                    <List>
                      {tertiaryItems.map((item, index) => footerLinkItem(item, index, SecondaryLink))}
                    </List>
                  </nav>
                )}
              </Column>
            )}
          </Grid.Row>
        </Grid.Container>
      </Container1 >
      <Container2>
        <Grid.Container>
          <Grid.Row config={{ gutters: ['left', 'right'] }}>
            <Column config={{ col: 12 }}>
              <ImagesContainer>
                <img alt={'aboriginal_flag'} src={aboriginalFlagSrcLink}/>
                <img alt={'torres_strait_islanders'} src={torresStraitFlagSrcLink}/>
              </ImagesContainer>
              {acknowledgmentCountry}
            </Column>
          </Grid.Row>
        </Grid.Container>
      </Container2>
      <Container2>
        <Grid.Container>
          <Grid.Row config={{ gutters: ['left', 'right'] }}>
            <Column config={{ col: 12 }}>
              <nav aria-label="footer accessibility and legal">
                <BottomList>
                
                {/* <button id="onetrust-pc-btn-handler" class="cookie-setting-link">Manage settings</button> */}
                  {isOneTrustScriptReady && bottomItems.map((item, index) => item.title.value !== 'Privacy Preferences' ? footerLinkItem(item, index, BottomLink): <PrivacyPreferencesLink 
                      className="optanon-toggle-display cookie-settings-button" 
                      title="Manage Cookie Preferences" 
                      aria-label="Manage Cookie Preferences" 
                      href="javascript:void(0);"
                      tabIndex={2}>
                        {item.title.value}
                    </PrivacyPreferencesLink>)}
                </BottomList>
              </nav>
            </Column>
          </Grid.Row>
        </Grid.Container>
      </Container2>
    </Footer>
  );
};

export default compose(withCoveoNoIndexTags, withSitecoreContext())(PageFooter);
