import { Image as SCImage } from "@sitecore-jss/sitecore-jss-react";
import styled from "styled-components";

import { Grid } from "_utils/components";
import { spacing } from "_utils/props";
import { headingH1, hideOnPrint, mq, subheading1 } from "_utils/styles";

const BANNER_MIN_HEIGHT_XS = 396;
const BANNER_MIN_HEIGHT_MD = 480;
const BANNER_MIN_HEIGHT_LG = 590;

export const Container = styled.div`
  align-items: flex-end;
  background-color: ${({ theme }) =>
    theme.campaignPageBanner.container.background};
  display: flex;
  min-height: ${BANNER_MIN_HEIGHT_XS}px;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq("md")} {
    min-height: ${BANNER_MIN_HEIGHT_MD}px;
  }

  ${mq("lg")} {
    min-height: ${BANNER_MIN_HEIGHT_LG}px;
  }

  @media print {
    background-color: ${({ theme }) =>
      theme.campaignPageBanner.container.mediaPrint.background};
    color: ${({ theme }) =>
      theme.campaignPageBanner.container.mediaPrint.color};
    min-height: 100%;
  }
`;

export const BannerGridContainer = styled(Grid.Container)`
  margin: 0;

  ${mq("xl")} {
    margin: 0 auto;
    width: 1024px;
  }

  ${({ theme }) =>
    spacing({
      theme,
      px: {
        xs: "20px",
        md: 3,
        lg: 4,
        xl: 0,
      },
      py: {
        xs: 5,
        md: 6,
        lg: 7,
      },
    })};
`;

export const BannerImageContainer = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
  user-select: none;

  ${hideOnPrint}
`;

export const Image = styled(SCImage)`
  font-family: "object-fit: cover;";
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Video = styled.video`
  font-family: "object-fit: cover;";
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const BannerContent = styled(Grid.Item)`
  @media print {
    ${({ theme }) => spacing({ theme, py: 3 })};
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const BannerHeading = styled.h1`
  ${headingH1}
  color: ${({ theme }) => theme.campaignPageBanner.bannerHeading.color};
  word-break: break-word;
  margin-bottom: 0;

  /* mq specificity required to override default heading margin-bottom */
  ${mq("md")} {
    margin-bottom: 0;
  }
`;

export const BannerDescription = styled.h2`
  color: ${({ theme }) => theme.campaignPageBanner.container.color};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 16px;

  /* mq specificity required to override default heading margin-bottom */
  ${mq("md")} {
    ${subheading1}
    margin-bottom: 0;
    margin-top: 16px;
  }
`;

export const BannerGradient = styled.div`
  /* https://larsenwork.com/easing-gradients */
  background: linear-gradient(
    to bottom,
    black,
    cubic-bezier(0.48, 0.3, 0.64, 1),
    transparent
  );
  background: linear-gradient(
    to top,
    hsl(0, 0%, 0%),
    hsla(0, 0%, 0%, 0.94505) 7.9%,
    hsla(0, 0%, 0%, 0.88294) 15.3%,
    hsla(0, 0%, 0%, 0.81522) 22.2%,
    hsla(0, 0%, 0%, 0.7426) 28.7%,
    hsla(0, 0%, 0%, 0.66692) 34.8%,
    hsla(0, 0%, 0%, 0.58891) 40.6%,
    hsla(0, 0%, 0%, 0.50925) 46.2%,
    hsla(0, 0%, 0%, 0.42866) 51.7%,
    hsla(0, 0%, 0%, 0.34817) 57.2%,
    hsla(0, 0%, 0%, 0.2693) 62.8%,
    hsla(0, 0%, 0%, 0.19309) 68.7%,
    hsla(0, 0%, 0%, 0.12126) 75.2%,
    hsla(0, 0%, 0%, 0.05882) 82.6%,
    hsla(0, 0%, 0%, 0.01457) 91.2%,
    hsla(0, 0%, 0%, 0)
  );
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  width: 100%;
`;
