import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  spacing,
  transitions,
} from '_utils/props';
import {
  caption2,
  depth,
  mq,
  showOnPrint,
  svg,
  button,
  textBody1
} from '_utils/styles';

export const SkipContainer = styled.div`
  background-color: ${({ theme }) => theme.pageHeader.skipContainer.background};
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: -9999px;
  transition: height 0.1s linear;
  z-index: ${depth.HEADER + 1};

  &.skipActive {
    height: 40px;
    top: 0;
  }
`;

export const SkipLink = styled.a`
  ${caption2}
  ${({ theme }) => spacing({ theme, px: 1, mt: `6px` })}

  &:focus {
    outline: 2px solid ${({ theme }) => theme.pageHeader.skipLink.focus.outlineColor};
  }

  color: ${({ theme }) => theme.pageHeader.skipLink.color};
  display: inline-block;
  line-height: 28px !important;
  opacity: 0;
  transition: opacity 0.2s linear;

  .skipActive & {
    opacity: 1;
  }
`;

export const PageHeaderWrapper = styled.div`
  height: ${({ theme }) => theme.pageHeader.height.xs}px;
  position: ${({ editMode, sticky }) => editMode || !sticky ? 'relative' : 'sticky'};
  top: ${({ editMode }) => editMode ? '0' : '-1px'};
  z-index: ${depth.HEADER};

  ${mq('md')} {
    height: ${({ theme }) => theme.pageHeader.height.md}px;
  }

  @media print {
    position: static;
  }

  ${mq.lessThan('md')} {
    ${({ theme }) => !theme.pageHeader.position.stickyOnMobile && 'position: relative'};
  }
`

export const PageHeaderWrapperSec = styled.div`
  height: 60px;
  position: sticky;
  top: ${({ sticky }) => sticky ? '73px' : '60px'};
  z-index: ${depth.HEADER};

  ${mq('lg')} {
    display: none;
  }

  ${mq('md')} {
    height: 60px;
  }

  @media print {
    position: static;
  }

  ${mq.lessThan('md')} {
    display: none;
    position: relative;
  }
`

export const StyledPageHeader = styled.header`
  background-color: ${({ theme }) => theme.pageHeader.background};
  border-bottom: 1px solid ${({ theme }) => theme.pageHeader.borderColor};
  width: 100%;
  z-index: ${depth.HEADER};
`;

export const PageHeaderContent = styled.div`
  ${({ theme }) => spacing({ theme, pl: { xs: `${theme.grid.gutter.size.xs}px`, md: 3 }, pr: { xs: 0, md: 3 } })}
  ${({ theme }) => transitions({ theme, transition: 'height' })};

  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.pageHeader.height.xs}px;
  justify-content: space-between;

  ${mq('md')} {
    height: ${({ theme }) => theme.pageHeader.height.md}px;

    
    ${({ sticky, pageDisplayCampaignNavigation }) => {
    if (sticky && !pageDisplayCampaignNavigation) {
      return css`
          height: ${({ theme }) => theme.pageHeader.height.fixed}px;         
        `;
    }
  }}
  }
`;

export const LogoLink = styled(Link)`
  line-height: 0;
  ${showOnPrint}
`;

export const LogoWrapper = styled.span`
  ${svg(92, 40)}
  flex: none;
  width: 92px;

  ${({ theme }) => transitions({ theme, transition: 'width' })};

  ${mq('md')} {
    ${({ theme }) => svg(theme.pageHeader.logo.svg.md.width, theme.pageHeader.logo.svg.md.height)};
    width: ${({ theme }) => theme.pageHeader.logo.svg.md.width}px;
  }

  ${mq('lg')} {
    ${({ theme }) => (theme?.pageHeader?.logo?.svg?.lg?.width && theme?.pageHeader?.logo?.svg?.lg?.height) ? svg(theme?.pageHeader?.logo?.svg?.lg?.width, theme?.pageHeader?.logo?.svg?.lg?.height) : null};
    width: ${({ theme }) => (theme?.pageHeader?.logo?.svg?.lg?.width) ? `${theme?.pageHeader?.logo?.svg?.lg?.width}px` : undefined};
  }

  ${({ sticky, pageDisplayCampaignNavigation }) => {
    if (sticky && !pageDisplayCampaignNavigation) {
      return css`
        ${mq('md')} {
          ${svg(110, 48)}
          width: 110px;
        }

        ${mq('lg')} {
          ${({ theme }) => (theme?.pageHeader?.logo?.svg?.sticky?.lg?.width && theme?.pageHeader?.logo?.svg?.lg?.height) ? svg(theme?.pageHeader?.logo?.svg?.sticky?.lg?.width, theme?.pageHeader?.logo?.svg?.lg?.height) : null};
          width: ${({ theme }) => (theme?.pageHeader?.logo?.svg?.sticky?.lg?.width) ? `${theme?.pageHeader?.logo?.svg?.sticky?.lg?.width}px` : undefined};
        }
      `;
    }
  }}
`;

const calcMenuOffset = (bp: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fixed' = 'xs', extra = 0) => ({
  theme,
  beforeRootSpace
}) => {
  const offset = theme.pageHeader.height[bp] + beforeRootSpace + extra;

  return css`
    height: calc(100% - ${offset}px);
    top: ${offset}px;
  `;
};

export const MenuContainer = styled.div`
  // height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  // top: 0;
  width: 100%;
  z-index: ${depth.MENU};

  height: calc(100% - ${153}px);
    top: ${153}px;

  ${mq('md')} {
    ${calcMenuOffset('md')}
    ${({ isSticky, pageDisplayCampaignNavigation }) => isSticky && !pageDisplayCampaignNavigation ? calcMenuOffset('fixed') : false}
  }


  &.skipActive {
    ${calcMenuOffset('xs', 32)}

    ${mq('md')} {
      ${calcMenuOffset('md', 32)}
    }
  }
`;

export const SearchContainer = styled.div`
  display: none;
  flex: 1 1 auto;
  height: 48px;

  ${({ theme }) => spacing({ theme, ml: 3 })}

  ${mq('md')} {
    display: block;
    flex-grow: 0;
    margin-left: auto;
    ${({ theme }) => spacing({ theme, pl: theme.pageHeader.searchContainer.md.paddingLeft })};
    width: ${({ theme }) => theme.pageHeader.searchContainer.width};
    ${({ theme }) => transitions({ theme, transition: 'width' })};
  }

  ${({ sticky, pageDisplayCampaignNavigation }) => {
    if (sticky && !pageDisplayCampaignNavigation) {
      return css`
        ${mq.between('md', 'lg')} {
          width: ${({ theme }) => theme.pageHeader.searchContainer.md.width};
        }
      `;
    }
  }}
`;

export const ITBSearchContainer = styled.div`
  display: none;
  height: 48px;
  ${({ theme }) => spacing({ theme, ml: 3 })}

  ${mq('md')} {
    display: flex;
    flex-direction: row-reverse;
    width: 700px;
    ${({ theme }) => spacing({ theme, pl: theme.pageHeader.searchContainer.md.paddingLeft })};
    ${({ theme }) => transitions({ theme, transition: 'width' })};
  }

  ${({ sticky, pageDisplayCampaignNavigation }) => {
    if (sticky && !pageDisplayCampaignNavigation) {
      return css`
        ${mq.between('md', 'lg')} {
          width: ${({ theme }) => theme.pageHeader.searchContainer.md.width};
        }
      `;
    }
  }}
`;

export const ITBSearchWrapper = styled.div`
  flex-grow: 1;
  min-width: 150px;
  max-width: 430px;

  ${mq('xl')} {
    width: 430px;
  }

  ${mq.lessThan('lg')} {
    display: none
  }

`

/* block interaction / darken body */
export const DimContent = styled.div`
  background-color: ${({ theme }) => theme.pageHeader.overlayColor};
  height: 100%;
  left: 0;
  opacity: 0.5;
  overflow-x: hidden;
  pointer-events: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${depth.MENU_COVER};
`;

/* block interaction over header */
export const MenuActiveBlockInteraction = styled.div`
  height: 100%;
  left: 0;
  pointer-events: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${depth.MENU_BLOCK};
`;

export const LinkButton = styled(Link)`
  ${({ theme }) => spacing({ theme, mr: { lg: 3, xl: 6} })};
  min-width: 210px;

  ${button('default', 'primary')};
      
  ${textBody1};
  margin-bottom: 0;

  path {
    fill: ${({ theme }) => theme.internalPageBanner.linkButton.pathFillColor};
  }
  

  a:focus:before {
     outline: 2px solid ${({ theme }) => theme?.internalPageBanner?.outline?.focusColor} !important;
  }

`;
