import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { ContactDetailsFormValues } from '_containers/MMFContactDetailsFormContext/definitions';
import { useLocationAPIContext } from '_containers/LocationAPIContext/index';
import Dropdown from '_utils/components/Form/Dropdown';
import FormLabel from '_utils/components/Form/Label';
import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { renderSearchPlaceholderForField } from '_utils/helpers/form';
import ErrorMessage from '_utils/components/Form/ErrorMessage';

import {
  AddressDropdownProps,
  AddressSelection,
} from './definitions';
import {
  ManualModeOption,
  ManualModeToggle,
} from './StyledDropdown';

const RESULTS_LIMIT = 100;

const AddressDropdown: React.FC<AddressDropdownProps> = ({
  formValuesKey,
  onEnterManually,
  onSelectAddressResult,
}) => {

  const [apiOptions, setApiOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addressSearchApi, addressResultApi } = useLocationAPIContext();
  const { values: formValues, setFieldValue } = useFormikContext<ContactDetailsFormValues>();


  const addressCountryIsoCode = formValues?.[formValuesKey]?.country?.isoCode2Letter;

  const addressDropdownLabel = getDictionaryItem("form-contact-address", "Address");
  const noOptionsText = getDictionaryItem("form-contact-no-results-found", "No results found");
  const enterManuallyText = getDictionaryItem("form-contact-enter-manually", "Enter manually");
  const cantFindAddressText = getDictionaryItem("form-contact-cant-find-address", "Can't find your address?");
  const addressRequiredText = getDictionaryItem("form-contact-notification-address-required", "Address is required");
  const addressDropdownPlaceholder = renderSearchPlaceholderForField(addressDropdownLabel);

  const {  getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(formValuesKey) ?? null;
  const hasError = !!((meta?.touched || meta?.initialTouched) && meta?.error);

  const fetchAddresses = (
    query?: string,
  ) => {
    if (!query) {
      setApiOptions([]);
      return;
    }

    setLoading(true);
    //NOSONAR
    addressSearchApi.request?.({
      axiosRequestConfig: {
        params: {
          countryIso: addressCountryIsoCode,
          query,
        },
      },
      onComplete: () => {
        setLoading(false);
      },
      onError: (err) => {
        console.warn(err);
      },
      onSuccess: (response) => {
        if ((response?.data?.items?.length ?? 0) > 0) {
          const filteredItems = response?.data?.items.map(({
            address,
            provider,
            identifier: id,
          }) => ({
            label: address,
            provider,
            id,
          } as AddressSelection));

          setApiOptions(filteredItems);
        }
      },
      useCachedResponse: false,
    });
  };

  const handleInputChange = (_, value: string) => fetchAddresses(value);

  const debouncedHandleInputChange = useDebouncedCallback(handleInputChange, 300)

  const handleAddressSelection = (selectedAddress: AddressSelection) => {
    //NOSONAR
    if (selectedAddress?.id && selectedAddress?.provider && addressCountryIsoCode) {
      addressResultApi?.request?.({
        axiosRequestConfig: {
          params: {
            provider: selectedAddress.provider,
            id: selectedAddress.id,
            countryIso: addressCountryIsoCode,
          },
        },
        onError: (err) => {
          console.warn(err);
        },
        onSuccess: (response) => {
          const addressUpdates = {
            addressLine1: response?.data?.address?.addressLines?.[0],
            addressLine2: response?.data?.address?.addressLines?.[1],
            addressLine3: response?.data?.address?.addressLines?.[2],
            postCode: response?.data?.postcode,
            city: response?.data?.suburb,
            state: {
              isoCode: response?.data?.state?.code,
              name: response?.data?.state?.name,
            },
            country: {
              isoCode2Letter: response?.data?.country?.iso,
              name: response?.data?.country?.name,
            },
          };

          setFieldValue(
            formValuesKey,
            {
              ...formValues?.[formValuesKey],
              ...addressUpdates,
            },
            false,
          );

          onSelectAddressResult?.();
        },
        useCachedResponse: false,
      });
    }
  };

  return (
    <>
      <FormLabel htmlFor={`${formValuesKey}`} required>{addressDropdownLabel}</FormLabel>
      <Dropdown
        className="api-lookup"
        filterOptions={x => x.slice(0, RESULTS_LIMIT)}
        getOptionLabel={(option) => option?.label}
        getOptionSelected={(option, value) => option.id === value.id}
        id={`${formValuesKey}`}
        loading={loading}
        multiple={false}
        noOptionsText={noOptionsText}
        onInputChange={debouncedHandleInputChange.callback}
        options={apiOptions}
        placeholder={addressDropdownPlaceholder}
        popupIcon="search"
        handleChange={(_, value, reason) => {
          if (reason === 'select-option') {
            handleAddressSelection?.(value);
          }
        }}
        PaperComponent={({ children, ...rest }) => {
          const footerRef = React.useRef();
          const paperRef = React.useRef();

          return (
            <Paper
              elevation={6}
              ref={paperRef}
              {...rest}
            >
              {children}
              <ManualModeOption ref={footerRef}>
                {cantFindAddressText}{' '}
                <ManualModeToggle
                  onClick={onEnterManually}
                  onMouseDown={event => {
                    event.preventDefault();
                  }}
                >
                  {enterManuallyText}
                </ManualModeToggle>
              </ManualModeOption>
            </Paper>
          );
        }}
      />
      {/*Note: the error style for Address dropdown is different from the dropdown. The dropdown is using 'name' for error controlling, address dropdown does not pass the prop*/}
      {/* Tracking this ticket for a fix - https://cpaaustralia.atlassian.net/browse/WP-3739 */}
      {hasError && <ErrorMessage>{addressRequiredText}</ErrorMessage>}
    </>
  );
};

export default AddressDropdown;
